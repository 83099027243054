var exports = {};
exports = {
  AUD: "AUD",
  BRL: "BRL",
  GBP: "GBP",
  BGN: "BGN",
  CAD: "CAD",
  CNY: "CNY",
  HRK: "HRK",
  CZK: "CZK",
  DKK: "DKK",
  EUR: "EUR",
  HKD: "HKD",
  HUF: "HUF",
  ISK: "ISK",
  IDR: "IDR",
  INR: "INR",
  ILS: "ILS",
  JPY: "JPY",
  MYR: "MYR",
  MXN: "MXN",
  NZD: "NZD",
  NOK: "NOK",
  PHP: "PHP",
  PLN: "PLN",
  RON: "RON",
  RUB: "RUB",
  SGD: "SGD",
  ZAR: "ZAR",
  KRW: "KRW",
  SEK: "SEK",
  CHF: "CHF",
  THB: "THB",
  TRY: "TRY",
  USD: "USD"
};
export default exports;